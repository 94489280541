import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";

const dashboardStyle = { display: "flex", flexDirection: "row" };
const contentStyle = { marginLeft: "300px", padding: "20px", flex: 1 };

// Container style for job card grid
const gridContainerStyle = {display: "flex",flexWrap: "wrap",justifyContent: "space-between",};  
const cardStyle = { flex: "0 0 calc(45% - 16px)", border: "1px solid #ddd", borderRadius: "8px", padding: "16px", boxShadow: "0 2px 4px rgba(0,0,0,0.1)", marginBottom: "16px",};  

const getAuthToken = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("No token found in localStorage");
  }
  return token;
};

function JobCard() {
  const [jobcards, setJobcards] = useState([]);

  const fetchJobcards = async () => {
    const token = getAuthToken();
    if (!token) return;

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch("https://api.mrmechanic.co/api/serviceModule/jobcards", requestOptions);
      if (!response.ok) {
        throw new Error("Failed to fetch jobcards");
      }
      const result = await response.json();
      setJobcards(result);
    } catch (error) {
      console.error("Error fetching jobcards:", error);
    }
  };

  useEffect(() => {
    fetchJobcards();
  }, []);

  return (
    <div>
      <Navbar />
      <div style={dashboardStyle}>
        <Sidebar />
        <div style={contentStyle}>
          <h1>Job Cards</h1>
          {jobcards.length === 0 ? (
            <p>No job cards found.</p>
          ) : (
            <div style={gridContainerStyle}>
              {jobcards.map((jobcard) => (
                <div key={jobcard._id} style={cardStyle}>
                  <h3>{jobcard.name} ({jobcard.registrationNumber})</h3>
                  <p><strong>Brand:</strong> {jobcard.brand}</p>
                  <p><strong>Year:</strong> {jobcard.year}</p>
                  {jobcard.owner && (
                    <p>
                      <strong>Owner:</strong> {jobcard.owner.name} ({jobcard.owner.email})
                    </p>
                  )}
                  <p><strong>Created At:</strong> {new Date(jobcard.createdAt).toLocaleString()}</p>
                  <p><strong>Updated At:</strong> {new Date(jobcard.updatedAt).toLocaleString()}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default JobCard;
