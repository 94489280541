import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FaUsers,
  FaChartLine,
  FaSignOutAlt, // Logout icon
  FaBox, // For Inventory Listings
  FaWarehouse, // For Location/Warehouses
  FaBuilding, // For Companies
  FaTools, // For Service Section
  FaClipboardList, // For Job Cards
  FaPlusSquare, // For Create/Edit Job Card
  FaSearch, // For Search
  FaUserTie, // For Labours
  FaClipboardCheck, // For Changelog
  FaCogs, // For Service Inventory
} from "react-icons/fa";
import AuthContext from "../context/AuthContext"; // Import AuthContext
import logo from "../assests/mrmechanics-logo1.png";

const Sidebar = () => {
  const { user, setUser } = useContext(AuthContext); // Access user and setUser from AuthContext
  const location = useLocation(); // Get current location
  const navigate = useNavigate(); // Hook for navigation

  const [openSection, setOpenSection] = useState(null); // State to track open section

  const sidebarStyle = {
    height: "100vh",
    width: "250px",
    backgroundColor: "#1a1e24",
    color: "#ffffff",
    padding: "20px",
    position: "fixed",
    overflowY: "scroll", // Allow scrolling
    scrollbarWidth: "none", // Hide scrollbar in Firefox
  };

  const logoStyle = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  };

  const listItemStyle = {
    listStyleType: "none",
    padding: "10px",
    borderRadius: "8px",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };

  const activeItemStyle = {
    backgroundColor: "#3b4b61",
  };

  const iconStyle = {
    marginRight: "10px",
  };

  const linkStyle = {
    color: "#ffffff",
    textDecoration: "none",
    fontSize: "16px",
  };

  const logoutButtonStyle = {
    marginTop: "20px",
    padding: "10px",
    backgroundColor: "#d9534f",
    border: "none",
    color: "white",
    borderRadius: "8px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    width: "100%",
    marginBottom: "200px",
  };

  const menuItems = [
    { path: "/dashboard", label: "Dashboard", icon: <FaChartLine style={iconStyle} /> },
    { path: "/users", label: "Users", icon: <FaUsers style={iconStyle} />, role: "superadmin" },
    { path: "/partnerList", label: "Partners List", icon: <FaUsers style={iconStyle} />, role: "admin" },

    // Inventory Section
    {
      label: "Inventory",
      icon: <FaBox style={iconStyle} />,
      onClick: () => setOpenSection(openSection === "inventory" ? null : "inventory"), // Toggle Inventory section
      subItems: [
        { path: "/inventory", label: "Inventory Listings", icon: <FaBox style={iconStyle} /> },
        { path: "/stock-transfer", label: "Stock Transfer", icon: <FaWarehouse style={iconStyle} /> },
        { path: "/inventory-updates", label: "Inventory Updates", icon: <FaBox style={iconStyle} />, role: "superadmin" },
        { path: "/inventory/companies", label: "Companies", icon: <FaBuilding style={iconStyle} /> },
        { path: "/inventory/locations", label: "Location / Warehouses", icon: <FaWarehouse style={iconStyle} /> },
      ],
    },

    // Service Section
    {
      label: "Service",
      icon: <FaTools style={iconStyle} />,
      onClick: () => setOpenSection(openSection === "service" ? null : "service"), // Toggle Service section
      subItems: [
        { path: "/service/jobcard", label: "Job Cards", icon: <FaClipboardList style={iconStyle} /> },
        { path: "/service/create-edit-job-card", label: "Create New Job Card / Edit Job Card", icon: <FaPlusSquare style={iconStyle} /> },
        { path: "/search", label: "Search", icon: <FaSearch style={iconStyle} /> },
        { path: "/labours", label: "Labours", icon: <FaUserTie style={iconStyle} /> },
        { path: "/service-inventory", label: "Service Inventory", icon: <FaCogs style={iconStyle} /> },
        { path: "/changelog", label: "Changelog", icon: <FaClipboardCheck style={iconStyle} />, role: "superadmin" },
      ],
    },
     // Other Sections (e.g., Purchase Orders, Sales, etc.)
    // { path: "/purchase-orders", label: "Purchase Orders", icon: <FaBox style={iconStyle} /> },
    // { path: "/sales", label: "Sales", icon: <FaClipboardList style={iconStyle} /> },
    // { path: "/service-job", label: "Service Jobs", icon: <FaCogs style={iconStyle} /> },
    // { path: "/quotations", label: "Quotations", icon: <FaClipboard style={iconStyle} /> },
  ];

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem("token"); // Remove token from local storage
    setUser(null); // Reset user in AuthContext
    navigate("/login"); // Redirect to login page
  };

  return (
    <div style={sidebarStyle}>
      <div style={logoStyle}>
        <img src={logo} alt="logo" style={{ height: "120px" }} />
      </div>
      <ul>
        {menuItems.map((item) => {
          if (item.role && item.role !== user?.role) return null; // Skip items not allowed for the user role

          return (
            <>
              <li
                key={item.label}
                style={{
                  ...listItemStyle,
                  ...(location.pathname === item.path ? activeItemStyle : {}),
                }}
                onClick={() => {
                  if (item.subItems) {
                    setOpenSection(openSection === item.label ? null : item.label); // Toggle current section
                  }
                }}
              >
                {item.icon}
                {item.path ? (
                  <Link to={item.path} style={linkStyle}>
                    {item.label}
                  </Link>
                ) : (
                  <span style={linkStyle}>{item.label}</span>
                )}
              </li>

              {/* Render the sub-items for Inventory, Service, or other sections (if open) */}
              {item.subItems && openSection === item.label && (
                <ul>
                  {item.subItems.map((subItem) => (
                    <li
                      key={subItem.path}
                      style={{
                        ...listItemStyle,
                        paddingLeft: "30px", // Indent the sub-menu items
                        ...(location.pathname === subItem.path ? activeItemStyle : {}),
                      }}
                    >
                      {subItem.icon}
                      <Link to={subItem.path} style={linkStyle}>
                        {subItem.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </>
          );
        })}
      </ul>
      <button style={logoutButtonStyle} onClick={handleLogout}>
        <FaSignOutAlt style={iconStyle} />
        Logout
      </button>
    </div>
  );
};

export default Sidebar;
