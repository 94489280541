import React, { useState, useEffect, useRef, useContext } from "react";
import { useDropzone } from "react-dropzone";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import AuthContext from "../context/AuthContext";

// Modal component for add inventory input
function InventoryModal({ isOpen, onClose, onSubmit }) {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    category: '',
    subCategories: [{ name: '', quantity: '', price: '', discountPercentage: '', taxableAmount: '', igstRate: '', igstAmount: '', totalAmount: '', uniqueId: '', type: '', measurement: '', location: '' }],
    images: [],
    SKU: '',
    company: ''
  });
  const [errors, setErrors] = useState({});
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const scrollToRef = useRef(null);

  const modalRef = useRef(); // Create a ref for the modal content

  useEffect(() => {
    const fetchCompanies = async () => {
      const headers = new Headers({
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      });
  
      try {
        const response = await fetch("https://api.mrmechanic.co/api/companies", {
          method: "GET",
          headers,
        });
  
        if (!response.ok) {
          throw new Error("Failed to fetch companies.");
        }
  
        const data = await response.json();
        setCompanies(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
  
    const fetchLocations = async () => {
      const headers = new Headers({
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      });
  
      try {
        const response = await fetch("https://api.mrmechanic.co/api/location", {
          method: "GET",
          headers,
        });
  
        if (!response.ok) {
          throw new Error("Failed to fetch locations.");
        }
  
        const data = await response.json();
        setLocations(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };
  
    fetchCompanies();
    fetchLocations();
  }, []);  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle image upload
  const handleDrop = (acceptedFiles) => {
    console.log("Files dropped:", acceptedFiles); // Debugging log

    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append("file", file);
    });

    // Send the file(s) to the backend (you need to create an endpoint to handle the S3 upload)
    fetch("https://api.mrmechanic.co/api/upload", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Upload response:", data); // Debugging log
        if (data.imageUrls) {
          setFormData({
            ...formData,
            images: data.imageUrls, // Save the returned image URLs
          });
        }
      })
      .catch((err) => console.error("Error uploading image:", err));
  };

  // Use react-dropzone hook for drag-and-drop file upload
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: "image/*", // Only accept image files
    multiple: true, // Allow multiple images
  });

  // Close modal on backdrop click
  const handleBackdropClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    // Add event listener to handle clicks outside of the modal
    document.addEventListener("mousedown", handleBackdropClick);

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleBackdropClick);
    };
  }, []);

  if (!isOpen) return null;

  const handleSubCategoryChange = (index, e) => {
    const { name, value } = e.target;
    const newSubCategories = [...formData.subCategories];
    newSubCategories[index][name] = value;
    setFormData({
      ...formData,
      subCategories: newSubCategories,
    });
  };

  const addSubCategory = () => {
    setFormData({
      ...formData,
      subCategories: [
        ...formData.subCategories,
        {
          name: "",
          quantity: 0,
          price: 0,
          uniqueId: "",
          type: "",
          measurement: "unit", // Default measurement unit
          location: "",
        },
      ],
    });
  };

  const toggleMeasurement = (index) => {
    const newSubCategories = [...formData.subCategories];
    newSubCategories[index].measurement =
      newSubCategories[index].measurement === "unit" ? "mL" : "unit"; // Toggle measurement
    setFormData({
      ...formData,
      subCategories: newSubCategories,
    });
  };

  // Validate the form
const validateForm = () => {
  let formErrors = {};
  
  if (!formData.name) formErrors.name = "Part Name is required";
  if (!formData.description) formErrors.description = "Description is required";
  if (!formData.category) formErrors.category = "Category is required";
  if (!formData.SKU) formErrors.SKU = "HSN Code is required";
  if (!formData.company) formErrors.company = "Company name is required";

  formData.subCategories.forEach((subCategory, index) => {
    if (!subCategory.name) formErrors[`subCategoryName${index}`] = `Subcategory ${index + 1} Name is required`;
    if (!subCategory.quantity) formErrors[`subCategoryQuantity${index}`] = `Subcategory ${index + 1} Quantity is required`;
    if (!subCategory.price) formErrors[`subCategoryPrice${index}`] = `Subcategory ${index + 1} Price is required`;
    if (!subCategory.uniqueId) formErrors[`subCategoryUniqueId${index}`] = `Subcategory ${index + 1} Unique ID is required`;
    if (!subCategory.location) formErrors[`subCategoryLocation${index}`] = `Subcategory ${index + 1} Location is required`;
    if (!subCategory.discountPercentage && subCategory.discountPercentage !== 0) 
      formErrors[`subCategoryDiscount${index}`] = `Subcategory ${index + 1} Discount Percentage is required`;
    if (!subCategory.taxableAmount && subCategory.taxableAmount !== 0) 
      formErrors[`subCategoryTaxableAmount${index}`] = `Subcategory ${index + 1} Taxable Amount is required`;
    if (!subCategory.igstRate && subCategory.igstRate !== 0) 
      formErrors[`subCategoryIgstRate${index}`] = `Subcategory ${index + 1} IGST Rate is required`;
    if (!subCategory.igstAmount && subCategory.igstAmount !== 0) 
      formErrors[`subCategoryIgstAmount${index}`] = `Subcategory ${index + 1} IGST Amount is required`;
    if (!subCategory.totalAmount && subCategory.totalAmount !== 0) 
      formErrors[`subCategoryTotalAmount${index}`] = `Subcategory ${index + 1} Total Amount is required`;
  });

  setErrors(formErrors);
  return Object.keys(formErrors).length === 0;
};
  
  const handleSubmit = () => {
    if (validateForm()) {
      console.log(formData); // Log formData before sending to the backend
      onSubmit(formData);
    } else {
      const firstErrorField = Object.keys(errors)[0];
      const element = document.getElementById(firstErrorField);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };  

  if (!isOpen) return null;

  // Inline styles for the modal
  const modalStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
    zIndex: 1000,
  };
  
  const modalContentStyle = {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    width: "90%", // Set width to 90% to take up more space
    maxWidth: "1000px", // Limit max width for larger screens
    maxHeight: "90vh", // Maximum height for the modal
    overflowY: "auto", // Allow scrolling if content exceeds modal height
    display: "flex",
    flexDirection: "column", // Make sure content is displayed in a column
    margin: "0 auto", // Center the modal horizontally
  };
  
  const scrollContainerStyle = {
    overflowY: "auto", // Allow scrolling without showing the scrollbar
    flexGrow: 1, // Allow the container to grow
    paddingRight: "10px", // Add padding to avoid content sticking to the edge
    scrollbarWidth: "none", // Firefox (hides scrollbar)
    "-ms-overflow-style": "none", // Internet Explorer and Edge
  };
  
  const inputStyle = {
    width: "98%", // Make inputs full width for better alignment
    padding: "12px",
    margin: "10px 0",
    borderRadius: "4px",
    border: "1px solid #ccc",
  };
  
  const labelStyle = {
    display: "block",
    margin: "5px 0",
    fontWeight: "bold",
  };
  
  const errorStyle = {
    color: "red",
    fontSize: "12px",
  };
  
  const toggleButtonStyle = {
    padding: "10px 20px",
    margin: "10px 0",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#4CAF50",
    color: "white",
    cursor: "pointer",
    textAlign: "center",
  };
  
  // Media query for responsiveness
  const mediaQueryStyle = {
    "@media (max-width: 768px)": {
      modalContentStyle: {
        width: "100%", // Take full width on mobile screens
        padding: "10px",
      },
      inputStyle: {
        padding: "10px", // Make inputs slightly smaller on mobile
      },
    },
  };

  return (
    <div style={modalStyle}>
      <div style={modalContentStyle} ref={modalRef}>
        <h2>Add Inventory</h2>
        <div>
          <form>
          <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
            <div style={{ flex: 1 }}>
              <label style={labelStyle}>Part Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter Part Name"
                style={inputStyle}
              />
              {errors.name && <div style={errorStyle} id="name">{errors.name}</div>}
            </div>

            <div style={{ flex: 1 }}>
              <label style={labelStyle}>Description</label>
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Enter item description"
                style={inputStyle}
              />
              {errors.description && <div style={errorStyle} id="description">{errors.description}</div>}
            </div>

            <div style={{ flex: 1 }}>
              <label style={labelStyle}>Category</label>
              <input
                type="text"
                name="category"
                value={formData.category}
                onChange={handleInputChange}
                placeholder="Enter category"
                style={inputStyle}
              />
              {errors.category && <div style={errorStyle} id="category">{errors.category}</div>}
            </div>
          </div>
            {formData.subCategories.map((subCategory, index) => (
             <div key={index} style={{ marginBottom: "20px" }}>
             <h4>Subcategory {index + 1}</h4>
           
             <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
               {/* Subcategory Name */}
               <div style={{ flex: 1 }}>
                 <label style={labelStyle}>Subcategory Name</label>
                 <input
                   type="text"
                   name="name"
                   value={subCategory.name}
                   onChange={(e) => handleSubCategoryChange(index, e)}
                   placeholder="Enter subcategory name"
                   style={inputStyle}
                 />
                 {errors[`subCategoryName${index}`] && (
                   <div style={errorStyle} id={`subCategoryName${index}`}>
                     {errors[`subCategoryName${index}`]}
                   </div>
                 )}
               </div>
           
               {/* Quantity */}
               <div style={{ flex: 1 }}>
                 <label style={labelStyle}>Quantity</label>
                 <input
                   type="number"
                   name="quantity"
                   value={subCategory.quantity}
                   onChange={(e) => handleSubCategoryChange(index, e)}
                   placeholder="Enter quantity"
                   style={inputStyle}
                 />
                 {errors[`subCategoryQuantity${index}`] && (
                   <div style={errorStyle} id={`subCategoryQuantity${index}`}>
                     {errors[`subCategoryQuantity${index}`]}
                   </div>
                 )}
               </div>
           
               {/* Price */}
               <div style={{ flex: 1 }}>
                 <label style={labelStyle}>Price</label>
                 <input
                   type="number"
                   name="price"
                   value={subCategory.price}
                   onChange={(e) => handleSubCategoryChange(index, e)}
                   placeholder="Enter price"
                   style={inputStyle}
                 />
                 {errors[`subCategoryPrice${index}`] && (
                   <div style={errorStyle} id={`subCategoryPrice${index}`}>
                     {errors[`subCategoryPrice${index}`]}
                   </div>
                 )}
               </div>
               </div>
               <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
               <div style={{ flex: 1 }}>
               <label style={labelStyle}>Discount Percentage</label>
              <input
                type="number"
                name="discountPercentage"
                value={subCategory.discountPercentage}
                onChange={(e) => handleSubCategoryChange(index, e)}
                style={inputStyle}
              />
              </div>
              <div style={{ flex: 1 }}>
              <label style={labelStyle}>Taxable Amount</label>
              <input
                type="number"
                name="taxableAmount"
                value={subCategory.taxableAmount}
                onChange={(e) => handleSubCategoryChange(index, e)}
                style={inputStyle}
              />
              </div>
              <div style={{ flex: 1 }}>
              <label style={labelStyle}>IGST Rate</label>
              <input
                type="number"
                name="igstRate"
                value={subCategory.igstRate}
                onChange={(e) => handleSubCategoryChange(index, e)}
                style={inputStyle}
              />
              </div>
              </div>
              <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
              <div style={{ flex: 1 }}>
              <label style={labelStyle}>IGST Amount</label>
              <input
                type="number"
                name="igstAmount"
                value={subCategory.igstAmount}
                onChange={(e) => handleSubCategoryChange(index, e)}
                style={inputStyle}
              />
              </div>
            <div style={{ flex: 1 }}>
              <label style={labelStyle}>Total Amount</label>
              <input
                type="number"
                name="totalAmount"
                value={subCategory.totalAmount}
                onChange={(e) => handleSubCategoryChange(index, e)}
                style={inputStyle}
              />
              </div>
            </div>     
  
             <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
              {/* Unique ID */}
              <div style={{ flex: 1 }}>
                <label style={labelStyle}>Unique ID</label>
                <input
                  type="text"
                  name="uniqueId"
                  value={subCategory.uniqueId}
                  onChange={(e) => handleSubCategoryChange(index, e)}
                  placeholder="Enter unique ID"
                  style={inputStyle}
                />
                {errors[`subCategoryUniqueId${index}`] && (
                  <div style={errorStyle} id={`subCategoryUniqueId${index}`}>
                    {errors[`subCategoryUniqueId${index}`]}
                  </div>
                )}
              </div>

                {/* Item Type */}
                <div style={{ flex: 1 }}>
                  <label style={labelStyle}>Item Type</label>
                  <select
                    name="type"
                    value={subCategory.type}
                    onChange={(e) => handleSubCategoryChange(index, e)}
                    style={inputStyle}
                  >
                    <option value="" disabled>
                      Select type
                    </option>
                    <option value="solid">Solid</option>
                    <option value="liquid">Liquid</option>
                  </select>
                </div>

                {/* Measurement */}
                <div style={{ flex: 1 }}>
                  <label style={labelStyle}>Measurement</label>
                  <div>
                    <span style={{ marginRight: "10px" }}>{subCategory.measurement}</span>
                    <button
                      type="button"
                      onClick={() => toggleMeasurement(index)}
                      style={toggleButtonStyle}
                    >
                      Toggle
                    </button>
                  </div>
                </div>
              </div>
              
                <label style={labelStyle}>Location</label>
                <select
                  name="location"
                  value={subCategory.location}
                  onChange={(e) => handleSubCategoryChange(index, e)}
                  style={inputStyle}
                >
                  <option value="">Select a location</option>
                  {locations.map((location) => (
                    <option key={location._id} value={location._id}>
                      {location.name}
                    </option>
                  ))}
                </select>
                {errors[`subCategoryLocation${index}`] && (
                  <div style={errorStyle} id={`subCategoryLocation${index}`}>
                    {errors[`subCategoryLocation${index}`]}
                  </div>
                )}
              </div>
            ))}
  
            <button
              type="button"
              onClick={addSubCategory}
              style={toggleButtonStyle}
            >
              Add Subcategory
            </button>
           <label style={labelStyle}>Images</label>
            <div
              {...getRootProps()}
              style={{
                border: "2px dashed #ccc",
                padding: "20px",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <input {...getInputProps()} />
              <p>Drag & drop images here, or click to select images</p>
            </div>
            <div style={{ marginTop: "20px" }}>
              {formData.images.length > 0 && (
                <ul>
                  {formData.images.map((url, index) => (
                    <li key={index}>
                      <img
                        src={url}
                        alt={`uploaded-img-${index}`}
                        width={100}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
  
            <div style={{ display: "flex", gap: "40px", marginBottom: "10px" }}>
              {/* HSN Code */}
              <div style={{ flex: 1 }}>
                <label style={labelStyle}>HSN Code</label>
                <input
                  type="text"
                  name="SKU"
                  value={formData.SKU}
                  onChange={handleInputChange}
                  placeholder="Enter HSN Code"
                  style={inputStyle}
                />
                {errors.SKU && <div style={errorStyle} id="SKU">{errors.SKU}</div>}
              </div>

              {/* Company */}
              <div style={{ flex: 1 }}>
                <label style={labelStyle}>Company</label>
                <select
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  style={inputStyle}
                >
                  <option value="">Select a company</option>
                  {companies.map((company) => (
                    <option key={company._id} value={company._id}>
                      {company.name}
                    </option>
                  ))}
                </select>
                {errors.company && <div style={errorStyle}>{errors.company}</div>}
              </div>
            </div>
            <button
              type="button"
              onClick={handleSubmit}
              style={toggleButtonStyle}
            >
              Submit
            </button>
          </form>
        </div>
        <button
          onClick={onClose}
          style={{ ...toggleButtonStyle, backgroundColor: "#f44336" }}
        >
          Close
        </button>
      </div>
    </div>
  );
}

function TransferModal({
  isOpen,
  onClose,
  subCategories,
  selectedSubCategory,
  onSubmit,
  setSelectedSubCategory,
}) {
  const [formData, setFormData] = useState({
    subCategoryName: "",
    newLocation: "",
    transferQuantity: 0,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (subCategories.length > 0) {
      setFormData({
        subCategoryName: subCategories[0].name, // Default to the first sub-category
        newLocation: subCategories[0].location,
        transferQuantity: 0, // Reset transfer quantity
      });
    }
  }, [subCategories]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.subCategoryName) {
      newErrors.subCategoryName = "Sub-category is required.";
    }

    if (!formData.newLocation) {
      newErrors.newLocation = "New location is required.";
    }

    if (
      !formData.transferQuantity ||
      formData.transferQuantity <= 0 ||
      formData.transferQuantity > selectedSubCategory?.availableQuantity
    ) {
      newErrors.transferQuantity =
        `Transfer quantity must be between 1 and ${selectedSubCategory?.availableQuantity || 0}.`;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    onSubmit({ ...formData });
  };

  if (!isOpen) return null;

  return (
    <div
      className="modal"
      style={{display: "flex",position: "fixed",top: 0,left: 0,width: "100%",height: "100%",justifyContent: "center",alignItems: "center",backgroundColor: "rgba(0, 0, 0, 0.7)",zIndex: 1000,
      }}
    >
      <div
        className="modal-content"
        style={{backgroundColor: "#f9f9f9",padding: "30px",borderRadius: "10px",boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",width: "500px",maxWidth: "90%",
        }}
      >
        <h2 style={{ color: "#333", fontSize: "22px", marginBottom: "20px" }}>
          Transfer Stock
        </h2>

        <label style={{ display: "block", marginBottom: "15px" }}>
          <span
            style={{fontSize: "14px",color: "#333",display: "block",marginBottom: "5px",
            }}
          >
            Sub-Category:
          </span>
          <select
            name="subCategoryName"
            value={formData.subCategoryName}
            onChange={(e) => {
              const selectedName = e.target.value;
              const selected = subCategories.find(
                (sub) => sub.name === selectedName
              );
              setSelectedSubCategory(selected); // Update the selected sub-category
              handleInputChange(e); // Update form data
            }}
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "6px",
              border: "1px solid #ddd",
              backgroundColor: "#f9f9f9",
              color: "#555",
              fontSize: "14px",
            }}
          >
            {subCategories.map((sub) => (
              <option key={sub.name} value={sub.name}>
                {sub.name}
              </option>
            ))}
          </select>
          {errors.subCategoryName && (
            <div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
              {errors.subCategoryName}
            </div>
          )}
        </label>

        <label style={{ display: "block", marginBottom: "15px" }}>
          <span
            style={{
              fontSize: "14px",
              color: "#333",
              display: "block",
              marginBottom: "5px",
            }}
          >
            New Location:
          </span>
          <select
            name="newLocation"
            value={formData.newLocation}
            onChange={handleInputChange}
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "6px",
              border: "1px solid #ddd",
              backgroundColor: "#f9f9f9",
              color: "#555",
              fontSize: "14px",
            }}
          >
            {subCategories.map((sub) => (
              <option key={sub.location} value={sub.location}>
                {sub.location}
              </option>
            ))}
          </select>
          {errors.newLocation && (
            <div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
              {errors.newLocation}
            </div>
          )}
        </label>

        <label style={{ display: "block", marginBottom: "20px" }}>
          <span>
            Transfer Quantity (Max: {selectedSubCategory?.availableQuantity || 0}):
          </span>
          <input
            type="number"
            name="transferQuantity"
            value={formData.transferQuantity}
            onChange={(e) => {
              const quantity = Number(e.target.value);
              handleInputChange(e);
            }}
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "6px",
              border: "1px solid #ddd",
              backgroundColor: "#f9f9f9",
              color: "#555",
              fontSize: "14px",
            }}
          />
          {errors.transferQuantity && (
            <div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
              {errors.transferQuantity}
            </div>
          )}
        </label>

        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
        >
          <button
            onClick={handleSubmit}
            style={{
              padding: "10px 20px",
              backgroundColor: "#28a745",
              color: "#fff",
              border: "none",
              borderRadius: "6px",
              cursor: "pointer",
              fontSize: "14px",
            }}
          >
            Submit
          </button>
          <button
            onClick={onClose}
            style={{
              padding: "10px 20px",
              backgroundColor: "#dc3545",
              color: "#fff",
              border: "none",
              borderRadius: "6px",
              cursor: "pointer",
              fontSize: "14px",
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

const EditInventoryModal = ({ item, setEditModalOpen, fetchInventory }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "",
    subCategories: [],
    SKU: "",
    images: [""],
    company: "",
  });

  const [errors, setErrors] = useState({});
  const inputRefs = useRef({});

  const modalStyle = {display: "flex",justifyContent: "center",alignItems: "center",position: "fixed",top: 0,left: 0,right: 0,bottom: 0,backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent backgroundzIndex: 1000,
  };
  
  const modalContentStyle = {backgroundColor: "#fff",padding: "20px",borderRadius: "8px",boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",width: "90%", // Set width to 90% to take up more spacemaxWidth: "1000px", // Limit max width for larger screensmaxHeight: "90vh", // Maximum height for the modaloverflowY: "auto", // Allow scrolling if content exceeds modal heightdisplay: "flex",flexDirection: "column", // Make sure content is displayed in a columnmargin: "0 auto", // Center the modal horizontally
  };
  
  const scrollContainerStyle = {overflowY: "auto", // Allow scrolling without showing the scrollbarflexGrow: 1, // Allow the container to growpaddingRight: "10px", // Add padding to avoid content sticking to the edgescrollbarWidth: "none", // Firefox (hides scrollbar)
    "-ms-overflow-style": "none", // Internet Explorer and Edge
  };
  
  const inputStyle = {width: "98%", // Make inputs full width for better alignmentpadding: "12px",margin: "10px 0",borderRadius: "4px",border: "1px solid #ccc",
  };
  
  const labelStyle = {display: "block",margin: "5px 0",fontWeight: "bold",
  };
  
  const errorStyle = {color: "red",fontSize: "12px",
  };
  
  const toggleButtonStyle = {padding: "10px 20px",margin: "10px 0",border: "none",borderRadius: "4px",backgroundColor: "#4CAF50",color: "white",cursor: "pointer",textAlign: "center",
  };
  
  // Media query for responsiveness
  const mediaQueryStyle = {
    "@media (max-width: 768px)": {
      modalContentStyle: {
        width: "100%", // Take full width on mobile screens
        padding: "10px",
      },
      inputStyle: {
        padding: "10px", // Make inputs slightly smaller on mobile
      },
    },
  };

  useEffect(() => {
    if (item) {
      setFormData({
        name: item.name,
        description: item.description,
        category: item.category,
        subCategories: item.subCategories,
        SKU: item.SKU,
        images: item.images,
        company: item.company,
      });
    }
  }, [item]);

  const handleEditSubCategoryChange = (index, field, value) => {
    const updatedSubCategories = [...formData.subCategories];
    updatedSubCategories[index][field] = value;
    setFormData({ ...formData, subCategories: updatedSubCategories });
  };

  const addEditSubCategory = () => {
    setFormData({
      ...formData,
      subCategories: [
        ...formData.subCategories,
        {
          name: "",
          quantity: 0,
          price: 0,
          uniqueId: "",
          type: "",
          measurement: "unit",
          location: "",
          discountPercentage: 0,
          taxableAmount: 0,
          igstRate: 0,
          igstAmount: 0,
          totalAmount: 0,
        },
      ],
    });
  };

  const toggleEditMeasurement = (index) => {
    const updatedSubCategories = [...formData.subCategories];
    updatedSubCategories[index].measurement =
      updatedSubCategories[index].measurement === "unit" ? "mL" : "unit";
    setFormData({ ...formData, subCategories: updatedSubCategories });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.description) newErrors.description = "Description is required.";
    if (!formData.category) newErrors.category = "Category is required.";
    if (!formData.SKU) newErrors.SKU = "HSN Code is required.";
    if (!formData.company) newErrors.company = "Company name is required.";

    formData.subCategories.forEach((subCategory, index) => {
      if (!subCategory.name) newErrors[`subCategoryName${index}`] = "Subcategory name is required.";
      if (!subCategory.quantity) newErrors[`subCategoryQuantity${index}`] = "Quantity is required.";
      if (!subCategory.price) newErrors[`subCategoryPrice${index}`] = "Price is required.";
      if (!subCategory.uniqueId) newErrors[`subCategoryUniqueId${index}`] = "Unique ID is required.";
      if (!subCategory.location) newErrors[`subCategoryLocation${index}`] = "Location is required.";
      if (!subCategory.discountPercentage && subCategory.discountPercentage !== 0)
        newErrors[`subCategoryDiscount${index}`] = "Discount Percentage is required.";
      if (!subCategory.taxableAmount && subCategory.taxableAmount !== 0)
        newErrors[`subCategoryTaxableAmount${index}`] = "Taxable Amount is required.";
      if (!subCategory.igstRate && subCategory.igstRate !== 0)
        newErrors[`subCategoryIgstRate${index}`] = "IGST Rate is required.";
      if (!subCategory.igstAmount && subCategory.igstAmount !== 0)
        newErrors[`subCategoryIgstAmount${index}`] = "IGST Amount is required.";
      if (!subCategory.totalAmount && subCategory.totalAmount !== 0)
        newErrors[`subCategoryTotalAmount${index}`] = "Total Amount is required.";
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const scrollToError = () => {
    const firstErrorKey = Object.keys(errors)[0];
    if (firstErrorKey && inputRefs.current[firstErrorKey]) {
      inputRefs.current[firstErrorKey].scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleEditSubmit = () => {
    if (!validateForm()) {
      scrollToError();
      return;
    }

    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(formData),
      redirect: "follow",
    };

    fetch(`https://api.mrmechanic.co/api/inventory/${item._id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        setEditModalOpen(false);
        fetchInventory();
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <h2>Edit Inventory</h2>
        <div style={scrollContainerStyle}>
          <form>
            <label style={labelStyle}>Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              ref={(el) => (inputRefs.current.name = el)}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              style={inputStyle}
            />
            {errors.name && <div style={errorStyle}>{errors.name}</div>}

            <label style={labelStyle}>Description</label>
            <input
              type="text"
              name="description"
              value={formData.description}
              ref={(el) => (inputRefs.current.description = el)}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              style={inputStyle}
            />
            {errors.description && <div style={errorStyle}>{errors.description}</div>}

            <label style={labelStyle}>Category</label>
            <input
              type="text"
              name="category"
              value={formData.category}
              ref={(el) => (inputRefs.current.category = el)}
              onChange={(e) => setFormData({ ...formData, category: e.target.value })}
              style={inputStyle}
            />
            {errors.category && <div style={errorStyle}>{errors.category}</div>}

            <label style={labelStyle}>Discount Percentage</label>
            <input
              type="number"
              name="discountPercentage"
              value={formData.discountPercentage}
              onChange={(e) => setFormData({ ...formData, discountPercentage: e.target.value })}
              style={inputStyle}
            />

            <label style={labelStyle}>Taxable Amount</label>
            <input
              type="number"
              name="taxableAmount"
              value={formData.taxableAmount}
              onChange={(e) => setFormData({ ...formData, taxableAmount: e.target.value })}
              style={inputStyle}
            />

            <label style={labelStyle}>IGST Rate</label>
            <input
              type="number"
              name="igstRate"
              value={formData.igstRate}
              onChange={(e) => setFormData({ ...formData, igstRate: e.target.value })}
              style={inputStyle}
            />

            <label style={labelStyle}>IGST Amount</label>
            <input
              type="number"
              name="igstAmount"
              value={formData.igstAmount}
              onChange={(e) => setFormData({ ...formData, igstAmount: e.target.value })}
              style={inputStyle}
            />

            <label style={labelStyle}>Total Amount</label>
            <input
              type="number"
              name="totalAmount"
              value={formData.totalAmount}
              onChange={(e) => setFormData({ ...formData, totalAmount: e.target.value })}
              style={inputStyle}
            />

            <label style={labelStyle}>HSN Code</label>
            <input
              type="text"
              name="SKU"
              value={formData.SKU}
              ref={(el) => (inputRefs.current.SKU = el)}
              onChange={(e) => setFormData({ ...formData, SKU: e.target.value })}
              style={inputStyle}
            />
            {errors.SKU && <div style={errorStyle}>{errors.SKU}</div>}

            <label style={labelStyle}>Image URL</label>
            <input
              type="text"
              name="images"
              value={formData.images[0]}
              onChange={(e) => {
                const newImages = [...formData.images];
                newImages[0] = e.target.value;
                setFormData({ ...formData, images: newImages });
              }}
              style={inputStyle}
            />

            <label style={labelStyle}>Company Name</label>
            <input
              type="text"
              name="company"
              value={formData.company}
              ref={(el) => (inputRefs.current.company = el)}
              onChange={(e) => setFormData({ ...formData, company: e.target.value })}
              style={inputStyle}
            />
            {errors.company && <div style={errorStyle}>{errors.company}</div>}
          </form>
        </div>

        <div style={{ marginTop: "20px", justifyContent: "space-between" }}>
          <button
            type="button"
            onClick={handleEditSubmit}
            style={toggleButtonStyle}
          >
            Save Changes
          </button>
          <button
            type="button"
            onClick={() => setEditModalOpen(false)}
            style={{
              ...toggleButtonStyle,
              backgroundColor: "#DC3545",
              marginLeft: "10px",
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

// Table for Inventory
function Inventory() {
  const { user } = useContext(AuthContext);
  const [inventoryList, setInventoryList] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const openEditModal = (item) => {
    setSelectedItem(item); 
    setIsEditModalOpen(true);
  };

  // Fetch the inventory list (GET request)
  const fetchInventory = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found. Please log in.");
      setLoading(false);
      return;
    }
  
    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
  
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
  
  fetch("https://api.mrmechanic.co/api/inventory", requestOptions)
  .then((response) => response.json())
  .then((result) => {
    if (!Array.isArray(result)) {
      throw new Error("Unexpected API response format");
    }
    const sortedInventory = result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setInventoryList(sortedInventory);
    setError(null);
    setLoading(false);
  })
  .catch((error) => {
    setError("Error fetching inventory.");
    setLoading(false);
  });
  };  

  //   Add inventory api call
  const handleFormSubmit = (data) => {
    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data), // Use form data collected from the modal
      redirect: "follow",
    };

    fetch("https://api.mrmechanic.co/api/inventory", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setIsModalOpen(false); // Close the modal after successful submission
        fetchInventory(); // Silently refresh the table
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchInventory();
  }, []);

  useEffect(() => {
    // Fetch companies and locations when the component mounts
    const fetchCompaniesAndLocations = async () => {
      const headers = new Headers({
        "x-auth-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      });
  
      try {
        // Fetch companies
        const companyResponse = await fetch("https://api.mrmechanic.co/api/companies", {
          method: "GET",
          headers,
        });
  
        if (!companyResponse.ok) {
          throw new Error("Failed to fetch companies.");
        }
  
        const companiesData = await companyResponse.json();
        setCompanies(companiesData); // Store the fetched companies in the state
  
        // Fetch locations
        const locationResponse = await fetch("https://api.mrmechanic.co/api/location", {
          method: "GET",
          headers,
        });
  
        if (!locationResponse.ok) {
          throw new Error("Failed to fetch locations.");
        }
  
        const locationsData = await locationResponse.json();
        setLocations(locationsData); // Store the fetched locations in the state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchCompaniesAndLocations();
  }, []);
  
  const getCompanyName = (companyId) => {
    const company = companies.find((company) => company._id === companyId);
    return company ? company.name : "Unknown";
  };

  const getLocationName = (locationId) => {
    const location = locations.find((location) => location._id === locationId);
    return location ? location.name : "Unknown"; 
  };
  

  //   Stock transfer api call
  const handleTransferSubmit = (data) => {
    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", localStorage.getItem("token"));
    myHeaders.append("Content-Type", "application/json");

    const transferQuantity = Number(data.transferQuantity); // Ensure transferQuantity is a valid number

    if (isNaN(transferQuantity) || transferQuantity <= 0) {
      alert("Please enter a valid transfer quantity greater than 0.");
      return;
    }

    // Ensure uniqueIds matches the transferQuantity
    const uniqueIds = selectedSubCategory.units
      .slice(0, transferQuantity) // Slice the units based on transferQuantity
      .map((unit) => unit.uniqueId); // Get unique IDs for the units to transfer

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({
        subCategoryName: data.subCategoryName,
        newLocation: data.newLocation,
        transferQuantity: transferQuantity, // Ensure transferQuantity is sent
        uniqueIds, // Add uniqueIds to the request body
      }),
      redirect: "follow",
    };

    fetch(
      `https://api.mrmechanic.co/api/inventory/transfer/${selectedSubCategory.parentId}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw new Error(error.message);
          });
        }
        return response.json();
      })
      .then((result) => {
        setIsTransferModalOpen(false);
        fetchInventory(); // Refresh inventory after transfer
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  // Filter inventory based on search term
  const filteredInventory = inventoryList.filter((item) => { 
    // Use a default empty string if name or company is missing
    const name = item.name || "";
    const company = item.company || "";
  
    // Convert both fields and searchTerm to lower case
    const lowerName = name.toLowerCase();
    const lowerCompany = company.toLowerCase();
    const lowerSearchTerm = searchTerm.toLowerCase();
  
    // Check if either field includes the search term
    return lowerName.includes(lowerSearchTerm) || lowerCompany.includes(lowerSearchTerm);
  });  

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredInventory.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredInventory.length / itemsPerPage);

  const dashboardStyle = {
display: "flex",
flexDirection: "row",
height: "100vh", 
  };
  const navbarStyle = {
width: "100%", 
position: "fixed", 
borderRight: "1px solid #ddd",
  };

  const sidebarStyle = {
width: "20%", 
position: "fixed",
height: "100vh", 
borderRight: "1px solid #ddd",
marginTop: "60px",
  };

  const contentStyle = {
marginTop: "2%",
marginLeft: "20%", 
padding: "20px",
flex: 1,
height: "calc(100vh - 60px)", 
    // overflowY: "auto", // Allow vertical scrolling for the whole content area
  };
  
  const tableStyle = {
width: "100%",
borderCollapse: "collapse",
marginBottom: "20px",
  };
  const thStyle = {
backgroundColor: "#4CAF50",
color: "white",
padding: "10px",
textAlign: "left",
  };

  const tdStyle = {
padding: "10px",
border: "1px solid #ddd",
  };

  return (
    <div>
      <div style={navbarStyle}>
        <Navbar />
      </div>
      <div style={dashboardStyle}>
        <div style={sidebarStyle}>
          <Sidebar />
        </div>
        <div style={contentStyle}>
          <h1>Inventory</h1>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <input
              type="text"
              placeholder="Search by name, company"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                padding: "10px",
                width: "300px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            />
            {user && user.role === "superadmin" && (
              <button
                onClick={() => setIsModalOpen(true)}
                style={{padding: "10px 20px",marginLeft: "20px",backgroundColor: "#4CAF50",color: "white",border: "none",borderRadius: "4px",cursor: "pointer",boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",transition: "background-color 0.3s",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = "#45a049")
                } // Darker green on hover
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "#4CAF50")
                } // Reset color
              >
                Add Inventory
              </button>
            )}
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <div>
      {loading ? (
        <p>Inventories Loading...</p>  // Show loading text
      ) : (
          <div>
            <table style={tableStyle}>
            <thead>
              <tr>
                {/* These columns span two rows */}
                <th style={thStyle} rowSpan="2">Part Name</th>
                <th style={thStyle} rowSpan="2">Category</th>
                <th style={thStyle} rowSpan="2">Item Name</th>
                <th style={thStyle} rowSpan="2">Item Location</th>
                <th style={thStyle} rowSpan="2">Item Price</th>
                <th style={thStyle} rowSpan="2">Less Disc%</th>
                <th style={thStyle} rowSpan="2">Taxable Amount</th>
                
                {/* Grouped header for IGST */}
                <th style={{ ...thStyle, textAlign: "center" }} colSpan="2">IGST</th>
                
                <th style={thStyle} rowSpan="2">Total Amount</th>
                <th style={thStyle} rowSpan="2">Item Quantity</th>
                <th style={thStyle} rowSpan="2">Item Type</th>
                <th style={thStyle} rowSpan="2">HSN Code</th>
                <th style={thStyle} rowSpan="2">Company</th>
                <th style={thStyle} rowSpan="2">Actions</th>
              </tr>
              <tr>
                {/* Sub-headers for IGST columns */}
                <th style={thStyle}>Rate%</th>
                <th style={thStyle}>Amount</th>
              </tr>
            </thead>
              <tbody>
                {currentItems.map((item) =>
                  item.subCategories.map((subCategory) => (
                    <tr key={subCategory._id}>
                      {subCategory === item.subCategories[0] ? (
                        <td rowSpan={item.subCategories.length} style={tdStyle}>
                          {item.name}
                        </td>
                      ) : null}
                      {/* {subCategory === item.subCategories[0] ? (
                        <td rowSpan={item.subCategories.length} style={tdStyle}>
                          {item.description}
                        </td>
                      ) : null} */}
                      {subCategory === item.subCategories[0] ? (
                        <td rowSpan={item.subCategories.length} style={tdStyle}>
                          {item.category}
                        </td>
                      ) : null}
                      <td style={tdStyle}>{subCategory.name}</td>
                      <td style={tdStyle}>{getLocationName(subCategory.location)}</td>
                      {/* <td style={tdStyle}>{subCategory.uniqueId}</td> */}
                      <td style={tdStyle}>
                      {subCategory.price != null && !isNaN(subCategory.price)
                        ? Number(subCategory.price).toFixed(1)
                        : 'N/A'}
                      </td>
                      <td style={tdStyle}>{subCategory.discountPercentage}</td>
                      <td style={tdStyle}>{subCategory.taxableAmount}</td>
                      <td style={tdStyle}>{subCategory.igstRate}</td>
                      <td style={tdStyle}>
                      {subCategory.igstAmount != null && !isNaN(subCategory.igstAmount)
                        ? Number(subCategory.igstAmount).toFixed(1)
                        : 'N/A'}
                      </td>
                      <td style={tdStyle}>
                      {subCategory.totalAmount != null && !isNaN(subCategory.totalAmount)
                        ? Number(subCategory.totalAmount).toFixed(1)
                        : 'N/A'}
                      </td>
                      <td style={tdStyle}>{subCategory.quantity}</td>
                      <td style={tdStyle}>{subCategory.type}</td>
                      {/* <td style={tdStyle}>{subCategory.unit}</td> */}
                      {subCategory === item.subCategories[0] ? (
                        <td rowSpan={item.subCategories.length} style={tdStyle}>
                          {item.SKU}
                        </td>
                      ) : null}
                      {/* {subCategory === item.subCategories[0] ? (
                      <td rowSpan={item.subCategories.length} style={tdStyle}>
                        {item.images}
                      </td>
                    ) : null} */}
                     {subCategory === item.subCategories[0] ? (
                      <td rowSpan={item.subCategories.length} style={tdStyle}>
                        {getCompanyName(item.company)} {/* Dynamically fetch the company name */}
                      </td>
                    ) : null}
                      {subCategory === item.subCategories[0] ? (
                        <td
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <button
                            style={{
                              padding: "5px 10px",
                              backgroundColor: "#007BFF",
                              color: "white",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                              marginRight: "10px",
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                            onClick={() => openEditModal(item)} // Open the modal with current data
                          >
                            Edit
                          </button>

                          {/* {user && user.role === "superadmin" && (
                            <button
                              style={{
                                padding: "5px 10px",
                                backgroundColor: "red",
                                color: "white",
                                border: "none",
                                borderRadius: "4px",
                                cursor: "pointer",
                                display: "inline-block",
                                verticalAlign: "middle",
                              }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to delete this inventory?"
                                  )
                                ) {
                                  const token = localStorage.getItem("token");

                                  if (!token) {
                                    console.error(
                                      "No token found. Please log in."
                                    );
                                    return;
                                  }

                                  const myHeaders = new Headers();
                                  myHeaders.append("x-auth-token", token);

                                  const requestOptions = {
                                    method: "DELETE",
                                    headers: myHeaders,
                                    redirect: "follow",
                                  };

                                  fetch(
                                    `https://api.mrmechanic.co/api/inventory/${item._id}`,
                                    requestOptions
                                  )
                                    .then((response) => {
                                      if (!response.ok) {
                                        throw new Error(
                                          `Error: ${response.status} ${response.statusText}`
                                        );
                                      }
                                      return response.text();
                                    })
                                    .then((result) => {
                                      console.log(result);
                                      // Optionally, refresh the inventory list after successful deletion
                                      fetchInventory();
                                    })
                                    .catch((error) =>
                                      console.error("Error:", error)
                                    );
                                }
                              }}
                            >
                              Delete
                            </button>
                          )} */}

                          {/* Stock transfer */}
                          <button
                            style={{
                              padding: "5px 10px",
                              backgroundColor: "gray",
                              color: "white",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                              marginLeft: "10px",
                            }}
                            onClick={() => {
                              const subCategoryWithQuantity = {
                                ...subCategory, // This assumes subCategory has an object structure that you need
                                parentId: item._id, // Store the parent category's _id
                                availableQuantity: subCategory.quantity, // Ensure you have the available quantity
                              };

                              setSelectedSubCategory(subCategoryWithQuantity); // Set the selected subcategory
                              setSelectedCategory(item); // Set the selected category here if needed
                              setIsTransferModalOpen(true); // Open the transfer modal
                            }}
                          >
                            Transfer
                          </button>
                        </td>
                      ) : null}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            </div>
            )}
            {error && <p style={{ color: "red" }}>{error}</p>}
          </div>

          <div style={{ marginTop: "20px", display: "flex", alignItems: "center", gap: "10px" }}>
            <span style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
              Page {currentPage} of {totalPages}
            </span>
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
              style={{
                padding: "10px 20px",
                backgroundColor: currentPage === 1 ? "#ccc" : "#007BFF",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: currentPage === 1 ? "not-allowed" : "pointer",
                transition: "background-color 0.3s, transform 0.2s",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              }}
              onMouseOver={(e) => {
                if (currentPage !== 1) e.currentTarget.style.backgroundColor = "#0056b3";
              }}
              onMouseOut={(e) => {
                if (currentPage !== 1) e.currentTarget.style.backgroundColor = "#007BFF";
              }}
              onMouseDown={(e) => {
                if (currentPage !== 1) e.currentTarget.style.transform = "scale(0.95)";
              }}
              onMouseUp={(e) => {
                if (currentPage !== 1) e.currentTarget.style.transform = "scale(1)";
              }}
            >
              Previous
            </button>
            <button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage(currentPage + 1)}
              style={{
                padding: "10px 20px",
                backgroundColor: currentPage === totalPages ? "#ccc" : "#007BFF",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: currentPage === totalPages ? "not-allowed" : "pointer",
                transition: "background-color 0.3s, transform 0.2s",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              }}
              onMouseOver={(e) => {
                if (currentPage !== totalPages) e.currentTarget.style.backgroundColor = "#0056b3";
              }}
              onMouseOut={(e) => {
                if (currentPage !== totalPages) e.currentTarget.style.backgroundColor = "#007BFF";
              }}
              onMouseDown={(e) => {
                if (currentPage !== totalPages) e.currentTarget.style.transform = "scale(0.95)";
              }}
              onMouseUp={(e) => {
                if (currentPage !== totalPages) e.currentTarget.style.transform = "scale(1)";
              }}
            >
              Next
            </button>
          </div>

          {/* add Inventory modal */}
          <InventoryModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSubmit={handleFormSubmit}
          />
          {isEditModalOpen && (
            <EditInventoryModal
              item={selectedItem}
              setEditModalOpen={setIsEditModalOpen} // Changed setIsModalOpen to setIsEditModalOpen
              fetchInventory={fetchInventory}
            />
          )}

          {/* Stock transfer modal */}
          {isTransferModalOpen && (
            <TransferModal
              isOpen={isTransferModalOpen}
              onClose={() => setIsTransferModalOpen(false)}
              subCategories={selectedCategory?.subCategories || []}
              selectedSubCategory={selectedSubCategory}
              setSelectedSubCategory={setSelectedSubCategory} // Pass it as a prop
              onSubmit={handleTransferSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Inventory;
