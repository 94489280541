import React from "react";
import { useNavigate } from "react-router-dom";

function MainHeader() {
  const navigate = useNavigate(); // Hook to access navigation

  const handleLoginClick = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard"); // Navigate to the dashboard if token exists
    } else {
      navigate("/login"); // Navigate to login if no token exists
    }
  };

  const navbarStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    backgroundColor: "#ff3a00",
    color: "white",
  };

  const leftContainerStyle = {
    display: "flex",
    alignItems: "center",
  };

  const logoStyle = {
    maxWidth: "50px",
  };

  const contactInfoStyle = {
    display: "flex",
    alignItems: "center",
    marginLeft: "20px",
  };

  const contactIconStyle = {
    marginLeft: "5px",
    fontSize: "20px",
  };

  const rightContainerStyle = {
    display: "flex",
    alignItems: "center",
  };

  const navbarLinksStyle = {
    listStyleType: "none",
    display: "flex",
    margin: "0",
    padding: "0",
  };

  const navbarLinkItemStyle = {
    marginRight: "20px",
  };

  const linkStyle = {
    color: "white",
    textDecoration: "none",
  };

  const secondaryButtonStyle = {
    backgroundColor: "white",
    color: "#ff3a00",
    border: "none",
    padding: "10px 20px",
    marginLeft: "10px",
    cursor: "pointer",
  };

  return (
    <div>
      <header style={navbarStyle}>
        <div style={leftContainerStyle}>
          <img
            src="https://plus.unsplash.com/premium_photo-1674375348380-c9bffa8c2ce8?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Y2FyJTIwbWVjaGFuaWNzfGVufDB8fDB8fHww"
            alt="Mr mechanic logo"
            style={logoStyle}
          />
          <div style={contactInfoStyle}>
            <span>+91-80-100-44000</span>
            <i className="fab fa-whatsapp" style={contactIconStyle}></i>
            <i className="fab fa-linkedin" style={contactIconStyle}></i>
          </div>
        </div>
        <nav style={rightContainerStyle}>
          <ul style={navbarLinksStyle}>
            <li style={navbarLinkItemStyle}>
              <a href="/aboutUs" style={linkStyle}>
                About Us
              </a>
            </li>
            <li style={navbarLinkItemStyle}>
              <a href="#" style={linkStyle}>
                Our Service
              </a>
            </li>
            <li style={navbarLinkItemStyle}>
              <a href="#" style={linkStyle}>
                Contact Us
              </a>
            </li>
          </ul>
          <button style={secondaryButtonStyle} onClick={handleLoginClick}>
            Login
          </button>
        </nav>
      </header>
    </div>
  );
}

export default MainHeader;
