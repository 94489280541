import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useNavigate } from "react-router-dom";

const dashboardStyle = { display: "flex", flexDirection: "row" };
const contentStyle = { marginLeft: "300px", padding: "20px", flex: 1 };

// Modal styling for a consistent medium size
const modalOverlayStyle = {position: "fixed",top: 0,left: 0,width: "100%",height: "100%",backgroundColor: "rgba(0,0,0,0.5)",display: "flex",alignItems: "center",justifyContent: "center",zIndex: 1000,};
const modalContentStyle = {backgroundColor: "#fff",padding: "20px",borderRadius: "8px",width: "500px",maxWidth: "90%",display: "flex",flexDirection: "column",};

// Table Styling
const tableStyle = {width: "100%",borderCollapse: "collapse",marginTop: "20px",};
const thStyle = {backgroundColor: "#f2f2f2",textAlign: "left",padding: "8px",};
const tdStyle = {padding: "8px",border: "1px solid #ddd",};

function CreateJobCard() {
  const navigate = useNavigate();
  const [bikes, setBikes] = useState([]);
  const [selectedBike, setSelectedBike] = useState(null);
  const [bikeHistory, setBikeHistory] = useState([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showCreateJobCardModal, setShowCreateJobCardModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    altMobile: "",
    email: "",
    city: "",
    state: "",
    bikeBrand: "",
    bikeYear: "",
    bikeName: "",
    bikeRegistrationNumber: "",
  });
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showJobCardCreationModal, setShowJobCardCreationModal] = useState(false);
  const [jobCardData, setJobCardData] = useState({
   registrationNumber: "",
   brand: "",
   name: "",
   year: "",
   ownerEmail: "",
  });
  const getAuthToken = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage");
    }
    return token;
  };

  const fetchBikes = async () => {
    const token = getAuthToken();
    if (!token) return;

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch("https://api.mrmechanic.co/api/serviceModule/bikes", requestOptions);
      if (!response.ok) {
        throw new Error("Failed to fetch bikes");
      }
      const result = await response.json();
      setBikes(result);
    } catch (error) {
      console.error("Error fetching bikes:", error);
    }
  };

  const fetchBikeDetails = async (registrationNumber) => {
    const token = getAuthToken();
    if (!token) return;

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(`https://api.mrmechanic.co/api/serviceModule/bike/${registrationNumber}`, requestOptions);
      if (!response.ok) {
        throw new Error("Failed to fetch Vehicle details");
      }
      const result = await response.json();
      setSelectedBike(result);
      setShowDetailsModal(true);  // Show modal with Vehicle details
    } catch (error) {
      console.error("Error fetching Vehicle details:", error);
      // If Vehicle is not found, open the job card modal to create a new one
      setShowCreateJobCardModal(true);
    }
  };

  const handleRegistrationSubmit = () => {
    if (registrationNumber) {
      fetchBikeDetails(registrationNumber);
    }
  };

  const fetchBikeHistory = async (registrationNumber) => {
    const token = getAuthToken();
    if (!token) return;

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(`https://api.mrmechanic.co/api/serviceModule/bike-history/${registrationNumber}`, requestOptions);
      if (!response.ok) {
        throw new Error("Failed to fetch Vehicle history");
      }
      const result = await response.json();
      setBikeHistory(result.history);
      setShowHistoryModal(true);
    } catch (error) {
      console.error("Error fetching Vehicle history:", error);
    }
  };

  useEffect(() => {
    fetchBikes();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    if (!token) return alert("No token found");
  
    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
    myHeaders.append("Content-Type", "application/json");
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(formData),
    };
  
    try {
      const response = await fetch("https://api.mrmechanic.co/api/serviceModule/register-bike", requestOptions);
      const result = await response.json();
  
      if (response.ok) {
        alert("Job card created successfully!");
        setShowCreateJobCardModal(false);  // Close the modal after successful submission
        setFormData({
          name: "",
          mobile: "",
          altMobile: "",
          email: "",
          city: "",
          state: "",
          bikeBrand: "",
          bikeYear: "",
          bikeName: "",
          bikeRegistrationNumber: "",
        });
        // Refresh the Vehicles list
        fetchBikes();
      } else if (result.message === "Vehicle already registered") {
        // Show a modal with the existing Vehicle details
        fetchBikeDetails(formData.bikeRegistrationNumber);
      } else {
        alert("Failed to create job card");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to create job card");
    }
  };

  const handleJobCardSubmit = async () => {
    const token = localStorage.getItem("token");
    if (!token) return alert("No token found");
  
    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
    myHeaders.append("Content-Type", "application/json");
  
    const raw = JSON.stringify({
      registrationNumber: jobCardData.registrationNumber,
      brand: jobCardData.brand,
      name: jobCardData.name,
      year: jobCardData.year,
      ownerEmail: jobCardData.ownerEmail,
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    try {
      const response = await fetch("https://api.mrmechanic.co/api/serviceModule/jobcard", requestOptions);
      if (!response.ok) {
        throw new Error("Failed to create job card");
      }
      const result = await response.json();
      console.log(result);
  
      // Show the success popup and then navigate after 2 seconds
      setShowJobCardCreationModal(false);
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        navigate("/service/jobcard");
      }, 2000);
    } catch (error) {
      console.error("Error creating job card:", error);
      alert("Error creating job card");
    }
  };
  
  // Close modal when clicking outside
  const closeModal = (e) => {
    if (e.target === e.currentTarget) {
      setShowCreateJobCardModal(false);
      setShowDetailsModal(false);
      setShowHistoryModal(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div style={dashboardStyle}>
        <Sidebar />
        <div style={contentStyle}>
        <input
         type="text"
         placeholder="Enter Registration Number"
         value={registrationNumber}
         onChange={(e) => setRegistrationNumber(e.target.value)}
        />
          <button onClick={handleRegistrationSubmit}>Search Vehicle</button>
          <h1>Registered Vehicles</h1>
          {bikes.length === 0 ? (
            <p>No Vehicles found.</p>
          ) : (
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>Registration Number</th>
                  <th style={thStyle}>Brand</th>
                  <th style={thStyle}>Year</th>
                  <th style={thStyle}>Name</th>
                  <th style={thStyle}>Owner Name</th>
                  <th style={thStyle}>Owner Email</th>
                  <th style={thStyle}>isDeleted</th>
                  <th style={thStyle}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {bikes.map((bike) => (
                  <tr key={bike._id}>
                    <td style={tdStyle}>{bike.registrationNumber}</td>
                    <td style={tdStyle}>{bike.brand}</td>
                    <td style={tdStyle}>{bike.year}</td>
                    <td style={tdStyle}>{bike.name}</td>
                    <td style={tdStyle}>{bike.owner?.name}</td>
                    <td style={tdStyle}>{bike.owner?.email}</td>
                    <td style={tdStyle}>{bike.isDeleted ? "Yes" : "No"}</td>
                    <td style={tdStyle}>
                      <button onClick={() => fetchBikeDetails(bike.registrationNumber)}>Get Details</button>{" "}
                      <button onClick={() => fetchBikeHistory(bike.registrationNumber)}>Get History</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {/* Register Vehicle modal */}
          {showCreateJobCardModal && (
            <div style={modalOverlayStyle} onClick={closeModal}>
              <div style={{gap: "10px",...modalContentStyle}}>
                <h2>Register Vehicle</h2>
                <input type="text" name="name" placeholder="Owner Name" value={formData.name} onChange={handleChange} />
                <input type="text" name="mobile" placeholder="Mobile" value={formData.mobile} onChange={handleChange} />
                <input type="text" name="altMobile" placeholder="Alt Mobile" value={formData.altMobile} onChange={handleChange} />
                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                <input type="text" name="city" placeholder="City" value={formData.city} onChange={handleChange} />
                <input type="text" name="state" placeholder="State" value={formData.state} onChange={handleChange} />
                <input type="text" name="VehicleBrand" placeholder="Vehicle Brand" value={formData.bikeBrand} onChange={handleChange} />
                <input type="text" name="VehicleYear" placeholder="Vehicle Year" value={formData.VehicleYear} onChange={handleChange} />
                <input type="text" name="VehicleName" placeholder="Vehicle Name" value={formData.bikeName} onChange={handleChange} />
                <input type="text" name="VehicleRegistrationNumber" placeholder="Registration Number" value={registrationNumber} onChange={handleChange} disabled/><button onClick={handleSubmit}>Submit</button>
                <button onClick={() => setShowCreateJobCardModal(false)}>Cancel</button>
              </div>
            </div>
          )}

          {/* Vehicle Details Modal */}
            {showDetailsModal && selectedBike && (
            <div style={modalOverlayStyle} onClick={closeModal}>
                <div style={modalContentStyle}>
                <h2>Vehicle Details</h2>
                <p><strong>ID:</strong> {selectedBike._id}</p>
                <p><strong>Registration:</strong> {selectedBike.registrationNumber}</p>
                <p><strong>Brand:</strong> {selectedBike.brand}</p>
                <p><strong>Name:</strong> {selectedBike.name}</p>
                <p><strong>Year:</strong> {selectedBike.year}</p>
                <p><strong>Owner:</strong> {selectedBike.owner?.name} ({selectedBike.owner?.email})</p>
                <p><strong>Created At:</strong> {new Date(selectedBike.createdAt).toLocaleString()}</p>
                <p><strong>Updated At:</strong> {new Date(selectedBike.updatedAt).toLocaleString()}</p>
                <p><strong>isDeleted:</strong> {selectedBike.isDeleted ? "Yes" : "No"}</p>
                <div style={{ display: "flex", gap: "10px" }}>
                    <button onClick={() => setShowDetailsModal(false)}>Close</button>
                    <button
                    onClick={() => {
                        setJobCardData({
                        registrationNumber: selectedBike.registrationNumber,
                        brand: selectedBike.brand,
                        name: selectedBike.name,
                        year: selectedBike.year,
                        ownerEmail: selectedBike.owner?.email || "",
                        });
                        setShowJobCardCreationModal(true);
                        setShowDetailsModal(false);
                    }}
                    >
                    Create Job Card
                    </button>
                </div>
                </div>
            </div>
            )}

          {/* Vehicle History Modal */}
          {showHistoryModal && (
            <div style={modalOverlayStyle} onClick={closeModal}>
              <div style={{gap: "10px",...modalContentStyle}}>
                <h2>Vehicle History</h2>
                {bikeHistory.length === 0 ? (
                  <p>No history found for this Vehicle.</p>
                ) : (
                  <table style={tableStyle}>
                    <thead>
                      <tr>
                        <th style={thStyle}>Timestamp</th>
                        <th style={thStyle}>Event</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bikeHistory.map((record, index) => (
                        <tr key={index}>
                          <td style={tdStyle}>{new Date(record.timestamp).toLocaleString()}</td>
                          <td style={tdStyle}>{record.event}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                <button onClick={() => setShowHistoryModal(false)}>Close</button>
              </div>
            </div>
          )}
          {/* Create Job modal */}
          {showJobCardCreationModal && (
            <div style={modalOverlayStyle} onClick={closeModal}>
                <div style={{ gap: "10px", ...modalContentStyle }} onClick={(e) => e.stopPropagation()}>
                <h2>Create Job Card</h2>
                <input type="text" name="registrationNumber" placeholder="Registration Number" value={jobCardData.registrationNumber} onChange={(e) => setJobCardData({ ...jobCardData, registrationNumber: e.target.value }) } disabled/>
                <input type="text" name="brand" placeholder="Brand" value={jobCardData.brand} onChange={(e) => setJobCardData({ ...jobCardData, brand: e.target.value }) }/>
                <input type="text" name="name" placeholder="Name" value={jobCardData.name} onChange={(e) => setJobCardData({ ...jobCardData, name: e.target.value }) }/>
                <input type="text" name="year" placeholder="Year" value={jobCardData.year} onChange={(e) => setJobCardData({ ...jobCardData, year: e.target.value }) }/>
                <input type="email" name="ownerEmail" placeholder="Owner Email" value={jobCardData.ownerEmail} onChange={(e) => setJobCardData({ ...jobCardData, ownerEmail: e.target.value }) }/>
                <button onClick={handleJobCardSubmit}>Submit</button>
                <button onClick={() => setShowJobCardCreationModal(false)}>Cancel</button>
                </div>
            </div>
            )}
            {showSuccessPopup && (
            <div style={{position: "fixed",top: "20px",right: "20px",background: "#4BB543",color: "#fff",padding: "10px 20px",borderRadius: "5px",zIndex: 2000,}}>
                Job card created successfully
            </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default CreateJobCard;
