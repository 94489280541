import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

function Location() {
  const [locations, setLocations] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [newLocation, setNewLocation] = useState({
    name: "",
    address: "",
    company: "",
  });
  const [editingLocation, setEditingLocation] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchLocations = async () => {
    setLoading(true);
    const headers = new Headers({
      "x-auth-token": localStorage.getItem("token"),
      "Content-Type": "application/json",
    });

    try {
      const response = await fetch("https://api.mrmechanic.co/api/location", {
        method: "GET",
        headers,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch locations.");
      }

      const data = await response.json();
      console.log("Fetched locations:", data); // Log response for debugging

      setLocations(data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanies = async () => {
    const headers = {
      "x-auth-token": localStorage.getItem("token"),
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch("https://api.mrmechanic.co/api/companies", {
        method: "GET",
        headers,
      });

      const data = await response.json();

      if (response.ok) {
        setCompanies(Array.isArray(data) ? data : []);
      } else {
        console.error("Error:", data.message);
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleCreateOrUpdate = async (e) => {
    e.preventDefault();
    const url = editingLocation
      ? `https://api.mrmechanic.co/api/location/${editingLocation._id}`
      : "https://api.mrmechanic.co/api/location";
    const method = editingLocation ? "PUT" : "POST";

    try {
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(newLocation),
      });

      const data = await response.json();

      if (response.ok) {
        fetchLocations(); // Re-fetch locations after creating/updating
        setNewLocation({ name: "", address: "", company: "" });
        setEditingLocation(null);
      } else {
        console.error("Error:", data.message);
      }
    } catch (error) {
      console.error("Error saving location:", error);
    }
  };

  const handleEdit = (location) => {
    setNewLocation({
      name: location.name,
      address: location.address,
      company: location.company?._id || "",
    });
    setEditingLocation(location);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this location?")) {
      try {
        const response = await fetch(
          `https://api.mrmechanic.co/api/location/${id}`,
          {
            method: "DELETE",
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );

        if (response.ok) {
          fetchLocations(); // Re-fetch locations after deleting
        } else {
          const data = await response.json();
          console.error("Error:", data.message);
        }
      } catch (error) {
        console.error("Error deleting location:", error);
      }
    }
  };

  useEffect(() => {
    fetchLocations();
    fetchCompanies();
  }, []);

  return (
    <div>
      <Navbar />
      <div style={dashboardStyle}>
        <Sidebar />
        <div style={contentStyle}>
          <h2>Locations</h2>
          <form style={formStyle} onSubmit={handleCreateOrUpdate}>
            <input
              type="text"
              placeholder="Name"
              value={newLocation.name}
              onChange={(e) =>
                setNewLocation({ ...newLocation, name: e.target.value })
              }
              required
              style={inputStyle}
            />
            <textarea
              placeholder="Address"
              value={newLocation.address}
              onChange={(e) =>
                setNewLocation({ ...newLocation, address: e.target.value })
              }
              required
              style={inputStyle}
            />
            <select
              value={newLocation.company}
              onChange={(e) =>
                setNewLocation({ ...newLocation, company: e.target.value })
              }
              required
              style={inputStyle}
            >
              <option value="">Select Company</option>
              {companies.map((company) => (
                <option key={company._id} value={company._id}>
                  {company.name}
                </option>
              ))}
            </select>
            <button type="submit" style={buttonStyle}>
              {editingLocation ? "Update Location" : "Create Location"}
            </button>
          </form>
          {loading ? (
            <p>Loading locations...</p>
          ) : locations.length === 0 ? (
            <p>No locations found.</p>
          ) : (
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Company</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {locations.map((location) => (
                  <tr key={location._id}>
                    <td>{location.name}</td>
                    <td>{location.address}</td>
                    <td>{location.company?.name || "N/A"}</td>
                    <td>
                      <button
                        style={editButtonStyle}
                        onClick={() => handleEdit(location)}
                      >
                        Edit
                      </button>
                      <button
                        style={deleteButtonStyle}
                        onClick={() => handleDelete(location._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

// Styles
const dashboardStyle = { display: "flex" };
const contentStyle = { marginLeft: "300px", padding: "20px", flex: 1 };
const formStyle = { display: "flex", flexDirection: "column", gap: "10px" };
const inputStyle = { padding: "10px", fontSize: "14px" };
const buttonStyle = {
  padding: "10px",
  backgroundColor: "#4CAF50",
  color: "white",
  border: "none",
  cursor: "pointer",
  borderRadius: "5px",
};
const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
  marginTop: "20px",
};
const editButtonStyle = {
  backgroundColor: "#2196F3",
  color: "white",
  padding: "5px 10px",
  borderRadius: "5px",
  marginRight: "5px",
  cursor: "pointer",
};
const deleteButtonStyle = {
  backgroundColor: "#f44336",
  color: "white",
  padding: "5px 10px",
  borderRadius: "5px",
  cursor: "pointer",
};

export default Location;
