import React, { useState, useEffect, useCallback } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Register from "../components/Register";

// New EditUserModal component
function EditUserModal({ user, onClose, onSave }) {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [status, setStatus] = useState(user.deleted ? "Inactive" : "Active");
  const [role, setRole] = useState(user.role);
  const [company, setCompany] = useState(user.company);

  const handleSubmit = async () => {
    const updatedUser = {
      name,
      email,
      status: status === "Active" ? false : true, // `deleted` is set to true if status is "Inactive"
      role,
      company,
    };

    try {
      const token = localStorage.getItem("token"); // Get the token from localStorage
      const myHeaders = new Headers();
      myHeaders.append("x-auth-token", token);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(updatedUser),
        redirect: "follow",
      };

      const response = await fetch(
        `https://api.mrmechanic.co/api/auth/users/${user._id}`, // PUT request to update the user
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        onSave(result); // Pass the updated user data back to the parent
      } else {
        console.error("Failed to update user:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }

    onClose(); // Close the modal after saving
  };

  const modalStyle1 = {position: "fixed",top: "50%",left: "50%",backgroundColor: "#fff",padding: "20px",borderRadius: "10px",boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",maxWidth: "500px",boxSizing: "border-box",transition: "all 0.3s ease",transform: "translate(-50%, -50%)",zIndex: 1000,width: "400px",
  };

  const overlayStyle1 = {position: "fixed",top: 0,left: 0,right: 0,bottom: 0,backgroundColor: "rgba(0, 0, 0, 0.5)",zIndex: 999,
  };

  // Inline styles
  const styles = {
    formGroup: {marginBottom: "15px"},
    input: {width: "100%",padding: "10px",fontSize: "16px",border: "1px solid #ccc",borderRadius: "5px",transition: "border-color 0.3s ease",boxSizing: "border-box",outline: "none",
    },
    label: {fontWeight: "600",display: "block",marginBottom: "5px",
    },
  };

  return (
    <>
      <div style={overlayStyle1} onClick={onClose} />
      <div style={modalStyle1}>
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Edit User</h2>
        <div style={styles.formGroup}>
          <label style={styles.label}>Name:</label>
          <input
            type="text"
            value={name}
            style={styles.input}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Email:</label>
          <input
            type="email"
            value={email}
            style={styles.input}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Status:</label>
          <input
            type="text"
            value={status}
            style={styles.input}
            onChange={(e) => setStatus(e.target.value)}
            disabled // Add this attribute to disable the input
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Role:</label>
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            style={styles.input} // keeping the same input style for consistency
          >
            <option value="customer">Customer</option>
            <option value="partner">Partner</option>
            <option value="admin">Admin</option>
            <option value="superadmin">Superadmin</option>
          </select>
        </div>
        {(role === "partner" || role === "admin") && (
          <div style={styles.formGroup}>
            <label style={styles.label}>Company</label>
            <input
              type="text"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              style={styles.input}
              placeholder="Enter your company name" // Placeholder for company
            />
          </div>
        )}

        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between", // Align buttons to the edges
          }}
        >
          <button
            onClick={handleSubmit}
            style={{
              padding: "12px",
              backgroundColor: "#1ca8cb",
              color: "#fff",
              fontSize: "16px",
              border: "none",
              borderRadius: "5px",
            }}
          >
            Save
          </button>
          <button
            onClick={onClose}
            style={{
              padding: "12px",
              backgroundColor: "red",
              color: "#fff",
              fontSize: "16px",
              border: "none",
              borderRadius: "5px",
            }}
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
}

function Users() {
  const [users, setUsers] = useState([]); // Initially empty, will load with dummy data
  const [showRegister, setShowRegister] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch users from API
  const fetchUsers = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.mrmechanic.co/api/auth/users",
        requestOptions
      );
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        console.error("Error fetching users:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  // Fetch users on component mount
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]); // Automatically re-fetches if fetchUsers changes

  const handleShowRegister = () => setShowRegister(true);
  const handleCloseRegister = () => setShowRegister(false);
  const handleCloseEditModal = () => setShowEditModal(false);
  const handleCloseConfirmModal = () => setShowConfirmModal(false); // Close the confirm modal

  // Show confirmation modal to activate/deactivate user
  const handleStatusChangeModal = (user) => {
    setSelectedUser(user); // Set the user to be modified
    setShowConfirmModal(true); // Open the confirmation modal
  };

  // Handle the API call to soft delete (change status to active/inactive)
  const handleConfirmStatusChange = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "DELETE", // DELETE request to change user status
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `https://api.mrmechanic.co/api/auth/users/${selectedUser._id}`, // Ensure correct user ID
        requestOptions
      );
      if (response.ok) {
        console.log(`User ${selectedUser.name} status updated.`);
        setShowConfirmModal(false); // Close modal after update
        fetchUsers(); // Refresh user list
      } else {
        console.error("Error updating user status:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Memoized function to handle editing a user
  const handleEditUser = useCallback((user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  }, []);

  // Refresh users list after saving
  const handleSaveUser = useCallback(
    (updatedUser) => {
      const updatedUsers = users.map((user) =>
        user.email === updatedUser.email ? updatedUser : user
      );
      setUsers(updatedUsers);
      fetchUsers(); // Refresh the list from the API
      setShowEditModal(false); // Close the modal
    },
    [users, fetchUsers]
  );

  const handleSearch = (event) => setSearchTerm(event.target.value);

  const handleEntriesChange = (event) =>
    setEntriesPerPage(parseInt(event.target.value, 10));

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Filtered users based on search term
  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.deleted
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      user.role.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user.company &&
        user.company.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Paginate users
  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const dashboardStyle = {display: "flex",flexDirection: "row",
  };

  const contentStyle = {marginLeft: "300px",padding: "20px",flex: 1,
  };

  const modalStyle = {position: "fixed",top: "50%",left: "50%",transform: "translate(-50%, -50%)",zIndex: 1000,width: "400px",
  };

  const overlayStyle = {position: "fixed",top: 0,left: 0,right: 0,bottom: 0,backgroundColor: "rgba(0, 0, 0, 0.5)",zIndex: 999,
  };

  const tableStyle = {width: "100%",borderCollapse: "collapse",marginTop: "20px",
  };

  const thTdStyle = {border: "1px solid #ddd",padding: "12px",textAlign: "left",
  };

  const headerStyle = {backgroundColor: "#f2f2f2",
  };

  const paginationStyle = {display: "flex",justifyContent: "space-between",marginTop: "20px",
  };

  return (
    <div>
      <Navbar />
      <div style={dashboardStyle}>
        <Sidebar />
        <div style={contentStyle}>
          <div
            style={{display: "flex",justifyContent: "space-between",alignItems: "center",marginBottom: "20px",}}
          >
            <h2>Users List</h2>
            <button
              style={{
                padding: "12px 24px",
                backgroundColor: "#1ca8cb",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                fontSize: "16px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Button shadow for depth
                transition: "background-color 0.3s",
              }}
              onClick={handleShowRegister}
            >
              Add New User
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              style={{
                padding: "8px",
                fontSize: "16px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginBottom: "20px",
              }}
            />
            <select
              value={entriesPerPage}
              onChange={handleEntriesChange}
              style={{
                padding: "8px",
                fontSize: "16px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginBottom: "20px",
              }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </select>
          </div>

          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={{ ...thTdStyle, ...headerStyle }}>Avatar</th>
                <th style={{ ...thTdStyle, ...headerStyle }}>Name</th>
                <th style={{ ...thTdStyle, ...headerStyle }}>Email</th>
                <th style={{ ...thTdStyle, ...headerStyle }}>Status</th>
                <th style={{ ...thTdStyle, ...headerStyle }}>Role</th>
                <th style={{ ...thTdStyle, ...headerStyle }}>Company</th>
                <th style={{ ...thTdStyle, ...headerStyle }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedUsers.map((user) => (
                <tr key={user.email} style={{ cursor: "pointer" }}>
                  <td style={thTdStyle}>
                    <img
                      src="https://plus.unsplash.com/premium_photo-1669863266283-557f9e170d85?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fGdhbmVzaGF8ZW58MHx8MHx8fDA%3D"
                      // src={user.avatar}
                      alt={user.name}
                      style={{
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  </td>
                  <td style={thTdStyle}>{user.name}</td>
                  <td style={thTdStyle}>{user.email}</td>
                  <td style={{ border: "1px solid #ddd", padding: "12px" }}>
                    <span
                      style={{
                        padding: "5px 10px",
                        backgroundColor: user.deleted ? "#dc3545" : "#28a745",
                        color: "#fff",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleStatusChangeModal(user)} // Trigger modal on click
                    >
                      {user.deleted ? "Inactive" : "Active"}
                    </span>
                  </td>
                  <td style={thTdStyle}>{user.role}</td>
                  <td style={thTdStyle}>{user.company || "N/A"}</td>
                  <td style={thTdStyle}>
                    <button
                      onClick={() => handleEditUser(user)}
                      style={{
                        padding: "8px 16px",
                        backgroundColor: "#1ca8cb",
                        color: "#fff",
                        border: "none",
                        borderRadius: "8px",
                        cursor: "pointer",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add some shadow for button depth
                      }}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Confirmation Modal for Status Change */}
          {showConfirmModal && selectedUser && (
            <div>
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 999,
                }}
                onClick={handleCloseConfirmModal}
              />
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1000,
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "10px",
                  width: "400px",
                }}
              >
                <h3 style={{ marginBottom: "20px" }}>
                  {selectedUser.deleted
                    ? "Please contact the superadmin to activate the user."
                    : "Are you sure you want to deactivate this user?"}
                </h3>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {!selectedUser.deleted && (
                    <button
                      onClick={handleConfirmStatusChange}
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#28a745",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      Yes
                    </button>
                  )}
                  <button
                    onClick={handleCloseConfirmModal}
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "#dc3545",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          <div style={paginationStyle}>
            <button
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              style={{
                margin: "0 5px",
                padding: "8px 12px",
                backgroundColor: "#1ca8cb",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of{" "}
              {Math.ceil(filteredUsers.length / entriesPerPage)}
            </span>
            <button
              disabled={
                currentPage === Math.ceil(filteredUsers.length / entriesPerPage)
              }
              onClick={() => handlePageChange(currentPage + 1)}
              style={{
                margin: "0 5px",
                padding: "8px 12px",
                backgroundColor: "#1ca8cb",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              Next
            </button>
          </div>

          {showRegister && (
            <>
              <div style={overlayStyle} onClick={handleCloseRegister} />
              <div style={modalStyle}>
                <Register onClose={handleCloseRegister} />{" "}
                {/* Pass the close function here */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically (if needed)
                  }}
                >
                  <button
                    onClick={handleCloseRegister}
                    style={{
                      justifyContent: "center",
                      width: "20%",
                      padding: "12px",
                      backgroundColor: "red",
                      color: "#fff",
                      fontSize: "16px",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      transition: "background-color 0.3s ease",
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </>
          )}

          {showEditModal && selectedUser && (
            <EditUserModal
              user={selectedUser}
              onClose={handleCloseEditModal}
              onSave={handleSaveUser}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Users;
