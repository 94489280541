import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const Login = () => {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(email, password);
  };

  return (
    <div style={styles.loginPage}>
      <div style={styles.loginContainer}>
        <h2 style={styles.heading}>Sign in to your account</h2>
        <p style={styles.subheading}>Enter your email & password to login</p>
        <form onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <label style={styles.label}>Email Address</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your email address"
              style={styles.inputField}
              required
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="********"
              style={styles.inputField}
              required
            />
          </div>
          <div style={styles.formOptions}>
            <div>
              <input type="checkbox" id="remember" />
              <label htmlFor="remember" style={styles.rememberLabel}>
                Remember password
              </label>
            </div>
            <a href="#" style={styles.forgotPassword}>
              Forgot Password
            </a>
          </div>
          <button type="submit" style={styles.submitBtn}>
            Sign in
          </button>
        </form>
      </div>
    </div>
  );
};

const styles = {
  loginPage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "97vh",
    backgroundImage:
      "url('https://images.unsplash.com/photo-1689907277936-88db528ef59f?w=600&auto=format&fit=crop&q=60')",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  loginContainer: {
    backgroundColor: "white",
    padding: "40px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "400px",
    width: "100%",
    textAlign: "center",
  },
  heading: {
    marginBottom: "10px",
    fontSize: "24px",
    color: "#333",
  },
  subheading: {
    marginBottom: "20px",
    color: "#666",
  },
  formGroup: {
    marginBottom: "20px",
    textAlign: "left",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    color: "#333",
  },
  inputField: {
    width: "100%",
    padding: "10px",
    fontSize: "14px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  formOptions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  rememberLabel: {
    fontSize: "14px",
    color: "#333",
  },
  forgotPassword: {
    fontSize: "14px",
    color: "red",
    textDecoration: "none",
  },
  submitBtn: {
    backgroundColor: "#007bff",
    color: "white",
    padding: "10px 0",
    width: "100%",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default Login;
