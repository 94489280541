import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [newCompany, setNewCompany] = useState({
    name: "",
    description: "",
    address: "",
  });
  const [editingCompany, setEditingCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch companies from the API
  const fetchCompanies = async () => {
    setLoading(true);
    const headers = new Headers({
      "x-auth-token": localStorage.getItem("token"),
      "Content-Type": "application/json",
    });

    try {
      const response = await fetch("https://api.mrmechanic.co/api/companies", {
        method: "GET",
        headers,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch companies.");
      }

      const data = await response.json();
      setCompanies(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error fetching companies:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle create or update company
  const handleCreateOrUpdate = async (e) => {
    e.preventDefault();
    const method = editingCompany ? "PUT" : "POST";
    const url = editingCompany
      ? `https://api.mrmechanic.co/api/companies/${editingCompany._id}`
      : "https://api.mrmechanic.co/api/companies";

    try {
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(newCompany),
      });

      if (!response.ok) {
        throw new Error("Failed to save company.");
      }

      setNewCompany({ name: "", description: "", address: "" });
      setEditingCompany(null);

      // Refresh the company list after create or update
      fetchCompanies();
    } catch (error) {
      console.error("Error saving company:", error);
    }
  };

  // Handle edit
  const handleEdit = (company) => {
    setNewCompany({
      name: company.name,
      description: company.description,
      address: company.address,
    });
    setEditingCompany(company);
  };

  // Handle delete
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this company?")) {
      try {
        const response = await fetch(
          `https://api.mrmechanic.co/api/companies/${id}`,
          {
            method: "DELETE",
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to delete company.");
        }

        // Refresh the company list after delete
        fetchCompanies();
      } catch (error) {
        console.error("Error deleting company:", error);
      }
    }
  };

  // Fetch companies when the component mounts
  useEffect(() => {
    fetchCompanies();
  }, []); // Empty dependency array ensures this runs only once after the initial render.

  return (
    <div>
      <Navbar />
      <div style={dashboardStyle}>
        <Sidebar />
        <div style={contentStyle}>
          <h2>Companies</h2>

          <form style={formStyle} onSubmit={handleCreateOrUpdate}>
            <input
              type="text"
              placeholder="Name"
              value={newCompany.name}
              onChange={(e) =>
                setNewCompany({ ...newCompany, name: e.target.value })
              }
              required
              style={inputStyle}
            />
            <textarea
              placeholder="Description"
              value={newCompany.description}
              onChange={(e) =>
                setNewCompany({ ...newCompany, description: e.target.value })
              }
              required
              style={inputStyle}
            />
            <input
              type="text"
              placeholder="Address"
              value={newCompany.address}
              onChange={(e) =>
                setNewCompany({ ...newCompany, address: e.target.value })
              }
              required
              style={inputStyle}
            />
            <button type="submit" style={buttonStyle}>
              {editingCompany ? "Update Company" : "Create Company"}
            </button>
          </form>

          {loading ? (
            <p>Loading companies...</p>
          ) : (
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Address</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {companies.map((company) => (
                  <tr key={company._id}>
                    <td>{company.name}</td>
                    <td>{company.description}</td>
                    <td>{company.address}</td>
                    <td>
                      <button
                        style={editButtonStyle}
                        onClick={() => handleEdit(company)}
                      >
                        Edit
                      </button>
                      <button
                        style={deleteButtonStyle}
                        onClick={() => handleDelete(company._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

const dashboardStyle = {
  display: "flex",
};

const contentStyle = {
  marginLeft: "300px",
  padding: "20px",
  flex: 1,
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  marginBottom: "20px",
};

const inputStyle = {
  padding: "10px",
  fontSize: "14px",
};

const buttonStyle = {
  padding: "10px",
  backgroundColor: "#4CAF50",
  color: "white",
  border: "none",
  cursor: "pointer",
  borderRadius: "5px",
};

const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
  marginTop: "20px",
};

const editButtonStyle = {
  backgroundColor: "#2196F3",
  color: "white",
  border: "none",
  padding: "5px 10px",
  borderRadius: "5px",
  marginRight: "5px",
  cursor: "pointer",
};

const deleteButtonStyle = {
  backgroundColor: "#f44336",
  color: "white",
  border: "none",
  padding: "5px 10px",
  borderRadius: "5px",
  cursor: "pointer",
};

export default Companies;
